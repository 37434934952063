import { Modal, message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { observable, action } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import type { Address } from './interface';
import { AddressModalStore } from './newAddress/store';

export default class Store {
  constructor() {
    this.onRefresh();
  }

  @observable
  public addressModalStore = new AddressModalStore(this);

  @observable
  public addressList: Address[] = [];

  @observable
  public defaultAddressId: number;

  @action
  public queryAddressList = async(): Promise<void> => {
    const res: BaseData<Address[]> = await request({ url: api.queryAddressList });
    if (this.defaultAddressId) {
      const list = res?.data?.filter((el) => el.id !== this.defaultAddressId);
      this.addressList.push(...list);
    } else {
      this.addressList.push(...this.addressList, ...res?.data);
    }
  };

  // 获取默认地址
  @action
  public getDefaultAddress = async(): Promise<void> => {
    const res: BaseData<Address> = await request({ url: api.queryMallDefaultAddress });
    if (!res.data) {
      return;
    }
    this.addressList = [res.data];
    if (!this.defaultAddressId) {
      this.defaultAddressId = res?.data?.id;
    }
  };

  @action
  public onClickNewAdd = (): void => {
    this.addressModalStore.onShow('add');
  };

  @action
  public editReceiverInfo = (id: number): void => {
    this.addressModalStore.onShow('edit', id);
  };

  @action
  public deleteReceiverInfo = (id: number): void => {
    // 删除地址
    Modal.confirm({
      title: '确认删除当前收货地址吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await request({
          url: api.deleteAddress,
          method: 'post',
          data: { ids: String(id) },
        });
        message.success('删除成功');
        this.onRefresh();
      },
    });
  };

  @action public onRefresh = async(): Promise<void> => {
    this.addressList = [];
    this.defaultAddressId = null;
    await this.getDefaultAddress();
    await this.queryAddressList();
  };
}
