import { Button, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { AddressModal } from './newAddress/index';
import { ReceiverInfo } from './receiverInfo';
import Store from './store';

const store = new Store();

const CommonAddress: React.FC<unknown> = observer((props) => {
  const { addressList, addressModalStore, onClickNewAdd, defaultAddressId, editReceiverInfo, deleteReceiverInfo } = store;
  return (
    <div className={styles.page}>
      <header className={styles.header}>
        常用地址
      </header>
      <div className={styles.content}>
        <header className={styles.contentHeader}>
          <Button
            onClick={onClickNewAdd}
            type="primary"
          >
            新增收货地址
          </Button>
        </header>
        <div className={styles.cardContent}>
          {addressList?.map((el) => {
            return (
              <ReceiverInfo
                address={el}
                defaultAddressId={defaultAddressId}
                key={el.id}
                onClickDelete={() => deleteReceiverInfo(el.id)}
                onClickEdit={() => editReceiverInfo(el.id)}
              />
            );
          })}
        </div>
      </div>
      <AddressModal store={addressModalStore}/>
    </div>
  );
});

export default CommonAddress;
