import { Button, Space, Tag } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { Address } from './interface';

interface IReceiverInfo {
  address: Address;
  defaultAddressId?: number;
  onClickEdit: () => void;
  onClickDelete: () => void;
}
export const ReceiverInfo: React.FC<IReceiverInfo> = observer((props) => {
  const { receiverName, id, city, province, district, receiverAddress, receiverMobile } = props.address;
  const { defaultAddressId, onClickEdit, onClickDelete } = props;
  return (
    <div className={styles.addressCard}>
      <header className={styles.name}>
        <span>
          {receiverName}
        </span>
        {id === defaultAddressId && (
          <div className={styles.defaultAddressIcon}>
            默认地址
          </div>
        )}
      </header>
      <div className={styles.addressCardContent}>
        <div>
          <div
            className={styles.address}
            title={`${province}${city}${district}${receiverAddress}`}
          >
            {`收货地址：${province}${city}${district}${receiverAddress}`}
          </div>
          <div className={styles.phoneNum}>
            {`手机号：${receiverMobile}`}
          </div>
        </div>
        <Space>
          <a onClick={onClickEdit}>
            编辑
          </a>
          <a onClick={onClickDelete}>
            删除
          </a>
        </Space>
      </div>
    </div>
  );
});
